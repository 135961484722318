<!-- layouts/SettingsLayout.vue -->

<template>
  <NuxtLayout name="portoll">
    <template #sidebarContent>
      <PortollSidebar />
    </template>

    <template #mainContent>
      <PortollHeaderBar />
      <div class="p-8">
        <h2 class="text-3xl font-bold mb-8">
          Portoll Settings
        </h2>
        <main class="bg-white drop-shadow-lg h-[902px] flex">
          <div class="max-w-[240px] w-full h-full border-r border-[#E2E8F0] flex-col flex py-7 px-3">
            <ul>
              <li
                v-for="link in links"
                :key="link.label"
                :class="{
                  'p-2 flex ml-2 text-sm py-[10px] px-[11px] text-[#475569] font-medium cursor-pointer hover:bg-[#EEF2FF] hover:text-[#6366F1] rounded': true,
                  'bg-[#EEF2FF] text-[#6366F1] rounded': isActive(link.to),
                }"
              >
                <Icon
                  :name="link.icon.name"
                  class="leading-0 mr-2 text-xl"
                />

                <nuxt-link :to="link.to">
                  {{ link.label }}
                </nuxt-link>
              </li>
            </ul>
          </div>

          <div class="w-[calc(100%-240px)] relative">
            <div class="py-6 px-7">
              <h2 class="text-2xl font-bold text-[#1E293B]">
                {{ fetchHeading(route.path) }}
              </h2>

              <div class="py-6">
                <slot name="main-content" />
              </div>
            </div>

            <div class="border-t border-1 border-[#E2E8F0] absolute bottom-0 right-0 w-full">
              <div class="text-end px-7 py-5">
                <PortollButton class="px-3 py-2 bg-transparent text-sm text-[#475569!important] border border-1 border-[#E2E8F0] font-normal mr-3">
                  Cancel
                </PortollButton>

                <PortollButton class="px-3 py-2 bg-[#6366F1] border border-1 border-[#6366F1] text-sm font-normal">
                  Save Changes
                </PortollButton>
              </div>
            </div>
          </div>
        </main>
      </div>
    </template>
  </NuxtLayout>
</template>

<script setup lang="ts">
  import { useRoute } from "vue-router";

  const route = useRoute();

  useHead({
    title: 'eLink Portoll - Setting'
  });

  const links = [
    {
      to: "/portoll/settings/",
      label: "My Portoll",
      icon: { name: "material-symbols:person" },
    },
    {
      to: "/portoll/settings/notifications",
      label: "My Notifications",
      icon: { name: "material-symbols:notifications" },
    },
    {
      to: "/portoll/settings/plans",
      label: "Subscription Plans",
      icon: { name: "material-symbols:sort" },
    },
    {
      to:"/portoll/settings/billing",
      label: "Billing & Invoices",
      icon: { name: "material-symbols:receipt" },
    },
  ];

  const isActive = (to: string) => route.path === to;

  const fetchHeading = (routeName: string) => {
    const activeRoute: any = links.find((link) => link.to === routeName);
    return activeRoute.label;
  };
</script>
